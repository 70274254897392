.mb-6 {
  margin-bottom: 6px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mt-8 {
  margin-bottom: 8px !important;
}

.mt-10 {
  margin-bottom: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

@media screen and (min-width: 992px) {
  .mb-md-6 {
    margin-bottom: 6px !important;
  }

  .mb-md-8 {
    margin-bottom: 8px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-12 {
    margin-bottom: 12px !important;
  }

  .mb-md-16 {
    margin-bottom: 16px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-24 {
    margin-bottom: 24px !important;
  }

  .mb-md-28 {
    margin-bottom: 28px !important;
  }

  .mb-md-32 {
    margin-bottom: 32px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-46 {
    margin-bottom: 46px !important;
  }

  .mb-md-48 {
    margin-bottom: 48px !important;
  }

  .mt-md-8 {
    margin-top: 8px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-12 {
    margin-top: 12px !important;
  }

  .mt-md-16 {
    margin-top: 16px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-24 {
    margin-top: 24px !important;
  }

  .mt-md-28 {
    margin-top: 28px !important;
  }

  .mt-md-32 {
    margin-top: 32px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-46 {
    margin-top: 46px !important;
  }

  .mt-md-48 {
    margin-top: 48px !important;
  }
}

.text-12 {
  font-size: 12px !important;
}

.text-14 {
  font-size: 14px !important;
}
.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

@media screen and (min-width: 992px) {
  .text-md-12 {
    font-size: 12px !important;
  }
  .text-md-14 {
    font-size: 14px !important;
  }
  .text-md-16 {
    font-size: 16px !important;
  }
  .text-md-18 {
    font-size: 18px !important;
  }
}

.color-black {
  color: black !important;
}
.color-white {
  color: white !important;
}
.color-gray-25 {
  color: var(--gray-25) !important;
}
.color-gray-500 {
  color: var(--gray-500) !important;
}
.color-brand-25 {
  color: var(--brand-25) !important;
}
.color-gray-950 {
  color: var(--gray-950) !important;
}
.weight-400 {
  font-weight: 400 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.font-family-nm {
  font-family: 'Neue Montreal' !important;
}
.font-family-ns {
  font-family: 'Nunito Sans' !important;
}
.flex-basis-50 {
  width: calc(50% - 16px) !important;
}
.flex-basis-33 {
  width: 33% !important;
}
.flex-basis-100 {
  width: 100% !important;
}
.flex-basis-70 {
  width: calc(70% - 16px);
}
.flex-basis-30 {
  width: calc(30% - 16px);
}
.rotate-180 {
  transform: rotate(180deg);
}
